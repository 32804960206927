<template>
    <div class="about">
        <h1>{{ msg }}</h1>
        <el-button @click="test" type="primary" size="medium">测试</el-button>
        <el-button @click="testxxx" type="primary" size="medium">设置缓存</el-button>
        <el-button @click="gettest" type="primary" size="medium">获取缓存</el-button>
        <el-button @click="getconfig" type="primary" size="medium">获取配置信息</el-button>
    </div>
</template>
<script>
export default {
    name: 'HelloWorld',
    data() {
        return {
            msg: '测试页面',
        }
    },
    methods: {
        test() {
            // let thi = this
            console.log('测试请求接口')
            let postdata = {
                api_name: 'test.test.isok'
            }
            this.Tool.post_data('test', postdata, (json) => {
                console.log(json)
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            console.log(111)
                        }
                    });
                }
            })

        },
        getconfig() {
            console.log('配置信息', this.env)
        },
        // 测试按钮
        testxxx() {
            let key = 'test';
            let data = [1, 2, 3]
            console.log('保存的数据', data)
            this.Tool.set_l_cache(key, data)

        },
        gettest() {
            let key = 'test';
            let data = this.Tool.get_l_cache(key)
            console.log('获取的数据', data)
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}
</style>
